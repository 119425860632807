import { Download } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';
import { ENTITIES } from 'API';
import { Storage } from 'aws-amplify';
import { downloadFileFromUrl } from 'components';
import { getDate, isBefore, parseISO } from 'date-fns';
import {
  UseExportEntitiesToCSV,
  UseImportCreativeEarnings,
} from 'hooks/query/useAdminActions';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { ACCEPT_CSV_FILES } from '../constants';
import FileUpload from './components/FileUpload';
import OopsModal from './components/OopsModal';
import { ErrorMessages, SuccessMessages } from './constants';
import { getAllWeeksOfMonth, isValidDateRange } from './utils';

const ImportTab: React.FC = () => {
  const currentDate = React.useMemo(() => new Date(), []);
  const [selectedRangeId, setSelectedRangeId] = useState<number | null>(null);
  const { importEarnings, error } = UseImportCreativeEarnings();
  const [isLoading, setIsLoading] = React.useState(false);
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [file, setFile] = React.useState<File | null>(null);
  const [modalMessage, setModalMessage] = React.useState<string | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const {
    exportEntities,
    url,
    loading: exportRequestsLoading,
  } = UseExportEntitiesToCSV();
  const onSelectFile = React.useCallback(async (files: File[]) => {
    const file = files?.[0];
    if (!file) {
      return;
    }

    if (file.type !== 'text/csv') {
      toast.error(ErrorMessages.InvalidFormat);
      return;
    }

    setFile(file);
    toast.success(SuccessMessages.FileSelected);
  }, []);

  const onError = useCallback(() => {
    if (!error?.message) {
      return;
    }

    setIsLoading(false);
    setModalMessage(error?.message?.replace('Error: ', ''));
  }, [error]);

  const onSuccess = () => {
    setIsLoading(false);
    toast.success(SuccessMessages.Imported);
  };

  useEffect(() => {
    onError();
  }, [error, onError]);

  const onSubmit = React.useCallback(async () => {
    if (!file) {
      toast.error(ErrorMessages.SelectFile);
      return;
    }

    const isValidRange = isValidDateRange(startDate, endDate);
    if (!endDate || !startDate || !isValidRange) {
      toast.error(ErrorMessages.InvalidDateRange);
      return;
    }

    setIsLoading(true);
    const key = `imports/creative-payments-${new Date().toISOString()}.csv`;
    await Storage.put(key, file, {
      contentType: 'text/csv',
      level: 'public',
      acl: 'public-read',
    });

    await importEarnings({
      variables: {
        toDate: endDate?.toISOString(),
        fromDate: startDate?.toISOString(),
        key: `public/${key}`,
      },
    });

    onSuccess();
  }, [endDate, file, importEarnings, startDate]);

  const onCancel = () => {
    setModalMessage('');
  };

  const generatedWeekButtons = useMemo(() => {
    const generatedWeeks = getAllWeeksOfMonth(currentDate);
    const weeks = generatedWeeks.map(([start, end]) => ({
      label:
        start && end
          ? `${getDate(parseISO(start))}-${getDate(parseISO(end))}`
          : '',
      start: parseISO(start || ''),
      end: parseISO(end || ''),
    }));

    return weeks.map((el, i) => ({
      id: ++i,
      end: el.end,
      start: el.start,
      value: el.label + 'th',
      isDisabled: isBefore(el.end, currentDate),
    }));
  }, [currentDate]);

  const handleRangeButtonClick = (id: number) => {
    setSelectedRangeId(id);
    if (selectedRangeId === id) {
      setSelectedRangeId(null);
      return;
    }

    const selectedWeek = generatedWeekButtons.find((b) => b.id === id);
    if (!selectedWeek) {
      return;
    }
    setStartDate(selectedWeek.start);
    setEndDate(selectedWeek.end);
  };

  const onExportRequests = useCallback(() => {
    exportEntities({
      variables: { entity: ENTITIES.ApprovedCreativeRequests },
    });
  }, [exportEntities]);

  useEffect(() => {
    if (url) {
      downloadFileFromUrl(url);
    }
  }, [url]);

  return (
    <div className="flex flex-col gap-9">
      <div className="flex justify-between w-full">
        <div className="max-w-[660px]">
          <div className="flex flex-col gap-9 w-full">
            <div className="flex gap-[15px] flex-wrap">
              {generatedWeekButtons.map((el) => {
                const isSelected = el.id === selectedRangeId;
                return (
                  <Button
                    key={el.id}
                    disableRipple={true}
                    onClick={() => handleRangeButtonClick(el.id)}
                    variant={isSelected ? 'contained' : 'outlined'}
                  >
                    {el.value}
                  </Button>
                );
              })}
            </div>

            <div>
              <FileUpload
                emptyMessage={file?.name}
                onFileSelect={onSelectFile}
                accept={ACCEPT_CSV_FILES}
              />
            </div>
          </div>
        </div>

        <div className="pl-10">
          <button
            className="bg-main-black text-white px-[22px] py-[4] w-[200px] h-[42px] rounded-[16px] text-[12px] disabled:bg-neutral-400 break-keep text-wrap"
            onClick={onExportRequests}
          >
            <div className="flex justify-evenly uppercase font-semibold items-center gap-x-1">
              {exportRequestsLoading ? (
                <CircularProgress className="text-white w-6 h-6" />
              ) : (
                <>
                  <Download />
                  <div>Creative Requests</div>
                </>
              )}
            </div>
          </button>
        </div>
      </div>

      <button
        onClick={onSubmit}
        disabled={isLoading}
        className="bg-main-black text-white px-[22px] py-[8px] rounded-[16px] text-[15px] break-keep text-wrap self-center font-semibold disabled:bg-gray-300"
      >
        {!isLoading ? (
          'Upload'
        ) : (
          <div className="flex justify-center items-center">
            <CircularProgress className="w-4 h-4 text-white" />
          </div>
        )}
      </button>

      {modalMessage && <OopsModal message={modalMessage} onCancel={onCancel} />}
    </div>
  );
};

export default ImportTab;

import { z } from 'zod';
import { ApprovedAdType } from '../../API';

export enum LinkAdDialogTitle {
  FailedToLink = 'Failed to link ad',
}

export const linkApprovedAdsTypesConstant = [
  {
    id: 1,
    label: 'TikTok',
    value: ApprovedAdType.TIKTOK,
  },
  {
    id: 2,
    label: 'Meta',
    value: ApprovedAdType.META,
  },
  {
    id: 3,
    label: 'Youtube Shorts',
    value: ApprovedAdType.YT_SHORTS,
  },
  {
    id: 4,
    label: 'DV 360',
    value: ApprovedAdType.DV360,
  },
  {
    id: 5,
    label: 'Manual',
    value: ApprovedAdType.MANUAL,
  },
];

export const APPROVED_ADS_FORM_LENGTH = 6;

export const rowSchema = z
  .object({
    adId: z
      .string()
      .refine((val) => !isNaN(Number(val)), {
        message: 'Only numbers are allowed',
      })
      .optional(),
    approvedAdType: z.string().optional(),
  })
  .refine(
    (data) => {
      const isAdIdPresent = Boolean(data.adId?.trim());
      const isApprovedAdTypePresent = Boolean(data.approvedAdType?.trim());

      return (
        (isAdIdPresent && isApprovedAdTypePresent) ||
        (!isAdIdPresent && !isApprovedAdTypePresent)
      );
    },
    {
      message: 'Both Ad ID and Channel must be provided or neither.',
      path: ['adId'],
    }
  );

export const formSchema = z
  .object({
    approvedAdsData: z.array(rowSchema),
  })
  .refine(
    (data) => {
      const adIds = data.approvedAdsData
        .map((approvedAd) => approvedAd.adId)
        .filter((approvedAd) => approvedAd);

      const filteredAdIds = [...new Set(adIds)];

      return adIds.length === filteredAdIds.length;
    },
    {
      message: 'Ad ID Should be unique',
      path: ['approvedAdsData'],
    }
  );

export type FormSchema = z.infer<typeof formSchema>;

import {
  ADMIN_STATUS,
  CreativeRequest,
  CREATIVE_REQUEST_LAST_UPDATED_BY,
  UserPaymentDetails,
} from 'API';
import AdminApprovalContent from 'components/adAdminApproval/Index';
import Modal from 'components/authentication/modal';
import Table, { Tdata } from 'components/table/Table';
import { useRequestStatusSendEmail } from 'hooks/query/useEmail';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { withAdmin } from 'state/admin';
import { AdminRoutes, AuthRoutes } from 'utils';
import { UpdateCreativeRequest } from '../../hooks';
import './adminDashboardTable.css';

type AdminDashboardTableProps = {
  paymentDetails: UserPaymentDetails[] | null;
  paymentLoading?: boolean;
  creativeRequestsData?: CreativeRequest[] | null;
  reqloading?: boolean;
  setIsShowBoxes: Dispatch<SetStateAction<boolean>>;
};

const TRANSACTIONS_TABLE_HEADER_CELLS = [
  'transactions',
  'numberOfTransactions',
  'totalAmount',
  'details',
];

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const AdminDashboardTable: FC<AdminDashboardTableProps> = ({
  paymentDetails,
  paymentLoading,
  creativeRequestsData,
  reqloading,
  setIsShowBoxes,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [tableData, setTableData] = useState<Tdata[]>([]);
  const [requestsTableData, setRequestsTableData] = useState<Tdata[] | null>(
    null
  );
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showInspiration, setShowInspiration] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCreativeRequest, setSelectedCreatveRequest] =
    useState<CreativeRequest | null>(null);
  const { updateRequest, loading: updateCreativeRequestLoading } =
    UpdateCreativeRequest();
  const { sendEmailData } = useRequestStatusSendEmail();

  const getTableData = (groupTransactionsMapData) => {
    const tableData = [...groupTransactionsMapData.entries()].map(
      (transaction) => ({
        transactions: GetDateMonthName(transaction[0]) || '',
        numberOfTransactions: transaction[1]?.length,
        totalAmount: `$${getTotalAmount(transaction[1])}`,
        transactionDate: transaction[0],
      })
    );

    setTableData(tableData);
  };

  const updateCreativeRequestStatus = async (
    newStatus: string,
    comment?: string,
    sendEmail?: boolean
  ) => {
    if (!selectedCreativeRequest) {
      return;
    }

    try {
      setLoading(true);

      const isApproved = newStatus === ADMIN_STATUS.Approved;
      const adminComment = [
        ...(selectedCreativeRequest.adminComment || []),
        comment || '',
      ];

      await updateRequest({
        variables: {
          input: {
            id: selectedCreativeRequest.id,
            adminApproval: ADMIN_STATUS[newStatus],
            lastUpdatedBy: CREATIVE_REQUEST_LAST_UPDATED_BY.ADMIN,
            ...(!isApproved && { adminComment }),
          },
        },
        errorPolicy: 'ignore',
      });

      if (sendEmail) {
        await sendEmailData({
          variables: {
            brandBriefId: selectedCreativeRequest.brandBriefId,
            creativeRequestUrl: `creatives?id=${selectedCreativeRequest.id}`,
            email: selectedCreativeRequest.creatorProfile?.email,
            name: selectedCreativeRequest?.creatorProfile?.name,
            brandBriefName: selectedCreativeRequest.BriefName,
            creativeUniqueId: selectedCreativeRequest.uniqueId,
            emailType: newStatus.toUpperCase(),
            feedback: comment || '',
          },
        });
      }

      setLoading(false);
      setShowSuccessModal(true);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getMakeGroup = useCallback(() => {
    const GroupMap = new Map();
    if (paymentDetails?.length) {
      for (const diteil of paymentDetails) {
        const { userTransactions } = diteil;
        if (userTransactions?.items.length) {
          for (const transaction of userTransactions.items) {
            const day = new Date(transaction?.createdAt || '');
            let date = '';
            day.setMonth(day.getMonth() + 1);
            date = `${
              new Date(transaction?.createdAt || '')
                .toLocaleString()
                .split(',')[0]
            }-${day.toLocaleString().split(',')[0]}`;
            if (!GroupMap.get(date)) {
              GroupMap.set(date, [transaction]);
            } else {
              const data = GroupMap.get(date);
              data.push(transaction);
              GroupMap.set(date, data);
            }
          }
        }
      }
    }
    const sortedArray: [string, string][] = Array.from(GroupMap).sort(
      (a, b) => {
        return (
          new Date(
            b[0].split('-')[0].split('.').reverse().join('-')
          ).getTime() -
          new Date(a[0].split('-')[0].split('.').reverse().join('-')).getTime()
        );
      }
    );
    const sortedMap: Map<string, string> = new Map(sortedArray);
    if (sortedMap.size && !tableData.length) getTableData(sortedMap);
  }, [paymentDetails]);

  const getTotalAmount = (data) =>
    Number(data.reduce((acc, item) => acc + item.paymentAmount, 0).toFixed(2));

  useEffect(() => {
    if (paymentDetails) {
      getMakeGroup();
    }
  }, [getMakeGroup, paymentDetails]);

  useEffect(() => {
    setRequestsTableData(
      (creativeRequestsData?.slice(0, 10) || [])?.map((request) => ({
        id: request?.id || '',
        img: request.creatorProfile?.avatar || '/images/default-image.png',
        creativeId: request.uniqueId,
        creatorName: request?.creatorProfile?.name,
        activationName: request?.brief?.BriefName,
      }))
    );
  }, [creativeRequestsData]);

  const GetDateMonthName = (date: string) => {
    const updatedDate = date?.split('-')[0];
    const dateMonth = updatedDate?.includes('.')
      ? updatedDate?.split('.')[1]
      : (updatedDate?.split('/')[1] as string | undefined);
    const dateYear = updatedDate?.includes('.')
      ? updatedDate?.split('.')[2]
      : updatedDate?.split('/')[2];

    let monthName = '';
    if (dateMonth) monthName = MONTHS[Number(dateMonth) - 1] || '';
    return `${monthName} ${dateYear}`;
  };

  const getLoadingStatus = () => !(!paymentLoading && tableData.length);
  useEffect(() => {
    const url = new URL(window.location.href);
    if (!url.searchParams.get('request')) {
      setIsShowBoxes(true);
      setShowInspiration(false);
      return;
    }

    setShowInspiration(true);
    setIsShowBoxes(false);
  }, [location]);

  return (
    <div className="">
      {showInspiration ? (
        <div className="">
          <div onClick={(e) => e.stopPropagation()} className="z-[99]">
            <AdminApprovalContent
              videoUrl={selectedCreativeRequest?.tiktokCreativeUrl || ''}
              onClose={() => setShowInspiration(true)}
              request={selectedCreativeRequest}
              createAdPayload={{}}
              updateCreativeRequestStatus={updateCreativeRequestStatus}
              type="Admin"
              reqLoading={loading}
            />
          </div>
          <Modal
            isOpen={showSuccessModal}
            handleClose={() => {
              setIsShowBoxes(true);
              setShowInspiration(false);
              setShowSuccessModal(false);
            }}
            type="brand"
            content="The Status of the creative request was successfully changed"
          />
        </div>
      ) : (
        <div className="grid grid-cols-12 gap-[30px]">
          <>
            <div className="md:col-span-6 col-span-12">
              <Table
                data={tableData as Tdata[]}
                rows={TRANSACTIONS_TABLE_HEADER_CELLS}
                loading={getLoadingStatus()}
                onTransationClick={(date) => {
                  navigate(
                    `/payment-window/${btoa(date.replaceAll('/', '.'))}`
                  );
                }}
                pagination={1}
                extended={false}
                rowWidth="w-[110px]"
                textPosition="text-left"
                borderColor="#FF872F"
              />
            </div>

            <div className="md:col-span-6 col-span-12">
              <Table
                mainlyData={creativeRequestsData as Tdata[]}
                data={requestsTableData}
                rows={[
                  'creativeId',
                  'activationName',
                  'creatorName',
                  'details',
                ]}
                loading={
                  reqloading ||
                  updateCreativeRequestLoading ||
                  !requestsTableData
                }
                pagination={1}
                extended={false}
                extendedURL={AdminRoutes.CreativeApproval}
                onRowClick={(request) => {
                  request && setSelectedCreatveRequest(request);

                  navigate(`${AuthRoutes.Dashboard}?request=true`);
                }}
                rowWidth="w-[110px]"
                borderColor="#FF872F"
                firstRowName="BRAND BRIEFS"
                textPosition="text-left"
              />
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default withAdmin(AdminDashboardTable);
